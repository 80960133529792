import { marketingCategories, salesCategories, serviceCategories } from '../constants/categories';
import intersection from 'hs-lodash/intersection';
export const getDisplayCategories = subCategories => {
  const displayCategories = [];
  if (intersection(marketingCategories, subCategories).length > 0) {
    displayCategories.push('MARKETING');
  }
  if (intersection(salesCategories, subCategories).length > 0) {
    displayCategories.push('SALES');
  }
  if (intersection(serviceCategories, subCategories).length > 0) {
    displayCategories.push('SERVICE');
  }
  if (intersection(subCategories, 'WEB_DESIGN').length > 0) {
    displayCategories.push('DESIGN');
  }
  return displayCategories;
};