import { registerQuery, useQuery } from 'academy-common-lib/utils/DataFetchingClient';
import http from 'hub-http/clients/noAuthApiClient';
const GET_PUBLIC_SEARCH = registerQuery({
  fieldName: 'publicSearch',
  args: ['searchTerm', 'language'],
  fetcher({
    searchTerm,
    language
  }) {
    // Manually making the request here because our DataFetchingClient doesn't
    // support GraphQL queries, and the HubSpot Apollo Client is configured
    // for CRM usage. We are only temporarily using the Web Team's GraphQL
    // search for consistency until we migrate to a new public search endpoint.
    return http.post('https://wtcfns.hubspot.com/academy-search/graphql', {
      data: {
        operationName: 'results',
        variables: {
          searchTerm,
          language
        },
        query: `query results($searchTerm: String, $language: Language) {
                  search(term: $searchTerm, language: $language) {
                    ... on Course {
                      id
                      pagePath
                      type
                      metadata {
                        name
                        __typename
                    }
                    __typename
                }
                  ... on Lesson {
                      id
                      type
                      pagePath
                      metadata {
                        name
                        __typename
                    }
                    __typename
                }
                  __typename
              }
            }
          `
      },
      withCredentials: false
    });
  }
});
export const usePublicSearch = (searchTerm, language) => {
  const {
    data,
    loading: publicSearchLoading,
    error: publicSearchError
  } = useQuery(GET_PUBLIC_SEARCH, {
    variables: {
      searchTerm,
      language
    },
    skip: !searchTerm
  });
  return {
    publicSearchData: data && data.data && data.data.search,
    publicSearchLoading,
    publicSearchError
  };
};