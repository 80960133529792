
(function() {
  var result = { translations: { "ro": { "i18n": { "duration": { "seconds": "{{ seconds }} sec", "minutes": "{{ minutes }} min", "hours": "{{ hours }} ore", "hoursAndMinutes": "{{ hours }} ore {{ minutes }} min", "days": "{{ days }} zile", "shortForm": { "seconds": "{{ seconds }}s", "minutes": "{{ minutes }}m", "hours": "{{ hours }}h", "days": "{{ days }}z", "hoursAndMinutes": "{{ hours }}h {{ minutes }}m" } }, "dateTime": { "quarterAndYear": "T{{quarterNumber}} {{yearNumber}}" }, "numberRepresentation": { "fraction": "{{ numerator }}/{{ denominator }}", "numberWithCurrencyCode": "{{currencyCode}} {{amountNumberValue}}", "phoneNumberWithExtension": "{{phoneNumber}}, ext. {{extension}}" } } } } };
  result.translationsLoaded = {
  "I18n": {
    "ro": "static-7.1200"
  }
};
  result.translationsAvailable = {
  "I18n": {
    "af": "static-7.1200",
    "ar-eg": "static-7.1200",
    "bg": "static-7.1200",
    "bn": "static-7.1200",
    "ca-es": "static-7.1200",
    "cs": "static-7.1200",
    "da": "static-7.1200",
    "de": "static-7.1200",
    "el": "static-7.1200",
    "en-gb": "static-7.1200",
    "en": "static-7.1200",
    "es-mx": "static-7.1200",
    "es": "static-7.1200",
    "et": "static-7.1200",
    "fi": "static-7.1200",
    "fr-ca": "static-7.1200",
    "fr": "static-7.1200",
    "he-il": "static-7.1200",
    "hi": "static-7.1200",
    "hr": "static-7.1200",
    "hu": "static-7.1200",
    "id": "static-7.1200",
    "it": "static-7.1200",
    "ja": "static-7.1200",
    "ko": "static-7.1200",
    "lt": "static-7.1200",
    "ms": "static-7.1200",
    "nl": "static-7.1200",
    "no": "static-7.1200",
    "pl": "static-7.1200",
    "pt-br": "static-7.1200",
    "pt-pt": "static-7.1200",
    "ro": "static-7.1200",
    "ru": "static-7.1200",
    "sk": "static-7.1200",
    "sl": "static-7.1200",
    "sv": "static-7.1200",
    "th": "static-7.1200",
    "tl": "static-7.1200",
    "tr": "static-7.1200",
    "uk": "static-7.1200",
    "vi": "static-7.1200",
    "x-pseudo": "static-7.1200",
    "zh-cn": "static-7.1200",
    "zh-hk": "static-7.1200",
    "zh-tw": "static-7.1200"
  }
};
  hns('I18n', result);
  if (typeof I18n === 'object' && I18n.trigger) { I18n.trigger('loaded:I18n:ro', {version: 'static-7.1200'}); }
})();


